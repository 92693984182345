<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                            <option value="" >Todos</option>
                            <option v-for="(item, index) in salesmanOrganizes(users)" :value="item.vendedor_usuario" :key="index">{{item.vendedor}}: {{item.vendedor_endereco}}</option>
                        </select>
                    </div><!-- /col -->
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Pesquisar vendedor...">
                    </div><!-- /col -->
                    <div class="col-auto pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <div class="btn-group w-100" :class="{'mb-3': platform == 'mobile'}">
                            <button type="button" class="btn btn-block btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span><i class="fas fa-print mr-2"></i>Impressão</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item" type="button" @click="printRelease(1)"><i class="fas fa-print mr-2"></i>Todos</button>
                                <button class="dropdown-item" type="button" @click="printRelease(3)"><i class="fas fa-arrow-down mr-2"></i>Retiradas</button>
                                <button class="dropdown-item" type="button" @click="printRelease(2)"><i class="fas fa-arrow-up mr-2"></i>Lançamentos</button>
                            </div>
                        </div>
                    </div><!-- /col -->
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="width:63.84px">Data</div>
                            </div>
                            <input class="form-control" type="date" @change="searchRelease">
                        </div>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="!loading && error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="!loading && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Não encontramos nenhum lançamento!</p>
                    </div><!-- /alert-danger -->
                    <div class="card mb-3" v-for="(item, index) in filteredListUsers" :key="index">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-8 mt-1 text-truncate">{{item.vendedor}}: {{item.vendedor_endereco}}</div>
                                <div class="col" :class="{'pr-0': platform == 'desktop'}">
                                    <button class="btn btn-sm btn-block btn-danger" @click="deleteRelease(item._id)">
                                        <template v-if="platform == 'desktop'">
                                            <i class="fas fa-trash mr-2"></i>Excluir
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-trash"></i>
                                        </template>
                                    </button>
                                </div>
                                <div class="col" v-if="platform == 'desktop'">
                                    <button class="btn btn-sm btn-block btn-primary" @click="printReleaseUser(item)">
                                        <i class="fas fa-print mr-2"></i>Imprimir
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row border-bottom pb-2">
                                <div class="col text-truncate">Nome</div>
                                <div class="col text-truncate">{{item.vendedor}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Usuário</div>
                                <div class="col text-truncate text-danger">{{item.vendedor_usuario}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Endereço</div>
                                <div class="col text-truncate">{{item.vendedor_endereco}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Descrição</div>
                                <div class="col text-truncate">{{item.descricao}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Responsável</div>
                                <div class="col text-truncate text-danger">{{item.responsavel}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Valor da transação</div>
                                <div class="col text-truncate">{{item.valor | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Tipo de lançamento</div>
                                <div class="col text-truncate" :class="{'text-success': item.tipo == 1, 'text-primary': item.tipo == 2}">{{item.tipo == 1 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2">
                                <div class="col text-truncate">Horário da operação</div>
                                <div class="col text-truncate">{{item.horario | formatDate}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            error: '',
            users: [],
            search: '',
            loading: false,
            isLoading: false,
            nothing_found: false,
            include_commission: 1,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        searchRelease(event) {

            const self = this;

            self.error = '';
            self.users = [];
            self.loading = true;
            self.nothing_found = false;

            api.get(`dashboard/releases/${event.target.value}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.users = response.data.releases.sort((a, b) => {

                            if (self.removeAccents(a.vendedor) < self.removeAccents(b.vendedor)) return -1;
                            if (self.removeAccents(a.vendedor) > self.removeAccents(b.vendedor)) return 1;

                            if (a.horario < b.horario) return 1;
                            if (a.horario > b.horario) return -1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = 'Ocorreu um erro, tente novamente!';
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        deleteRelease(id) {

            const self = this;
            const index = self.users.findIndex((item) => item._id == id);

            Swal.fire({
                title: 'Excluir lançamento',
                html: `Você deseja mesmo excluir o <br /> lançamento do vendendor "<b>${self.users[index].vendedor_usuario}</b>" ?<p class="mt-3 pt-3 border-top">Valor da transação: <b>${self.formatCoin(self.users[index].valor)}</b> <br /> Horário do lançamento: <b>${self.formatDate(self.users[index].horario)}</b></p>`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then((result) => {

                if (result.value) {
                    
                    self.isLoading = true;

                    api.delete(`dashboard/releases/${id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':

                                self.users.splice(index, 1);

                                Swal.fire(
                                    'Parabéns!',
                                    'O lançamento foi excluído com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                         try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        printRelease(type) {

            let html = ``;
            let self = this;

            if (self.users.length == 0) {
                Swal.fire(
                    'Falha',
                    'Não encontramos nenhum lançamento disponível!',
                    'error'
                );
            } else {
                
                for (let user of self.users) {

                    if (type == 1) {
                        html += `
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${user.vendedor.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${user.vendedor_endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Usuário</td>
                                    <td align="center" width="50%">${user.vendedor_usuario}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Descrição</td>
                                    <td align="center" width="50%">${user.descricao}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Responsável</td>
                                    <td align="center" width="50%">${user.responsavel}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Valor da transação</td>
                                    <td align="center" width="50%">${self.formatCoin(user.valor)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Tipo de lançamento</td>
                                    <td align="center" width="50%">${user.tipo == 1 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Horário da operação</td>
                                    <td align="center" width="50%">${self.formatDate(user.horario)}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    } else if (type == 2 && user.tipo == 1) {
                        html += `
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${user.vendedor.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${user.vendedor_endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Usuário</td>
                                    <td align="center" width="50%">${user.vendedor_usuario}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Descrição</td>
                                    <td align="center" width="50%">${user.descricao}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Responsável</td>
                                    <td align="center" width="50%">${user.responsavel}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Valor da transação</td>
                                    <td align="center" width="50%">${self.formatCoin(user.valor)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Tipo de lançamento</td>
                                    <td align="center" width="50%">${user.tipo == 1 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Horário da operação</td>
                                    <td align="center" width="50%">${self.formatDate(user.horario)}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    } else if (type == 3 && user.tipo == 2) {
                        html += `
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${user.vendedor.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${user.vendedor_endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Usuário</td>
                                    <td align="center" width="50%">${user.vendedor_usuario}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Descrição</td>
                                    <td align="center" width="50%">${user.descricao}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Responsável</td>
                                    <td align="center" width="50%">${user.responsavel}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Valor da transação</td>
                                    <td align="center" width="50%">${self.formatCoin(user.valor)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Tipo de lançamento</td>
                                    <td align="center" width="50%">${user.tipo == 1 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Horário da operação</td>
                                    <td align="center" width="50%">${self.formatDate(user.horario)}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    }
                }

                if (!html) {
                    Swal.fire(
                        'Falha',
                        'Nenhum lançamento disponível para a opção escolhida!',
                        'error'
                    );
                } else {
                    self.printIframeContentWindow(html);
                }
            }
        },
        printReleaseUser(user) {

            let self = this;

            let html = `
            <table class="table" border="1">
                <tbody>
                    <tr>
                        <td colspan="12" align="center" bgcolor="#ddd">
                            <strong>
                                <p style="margin-bottom:5px">${user.vendedor.toUpperCase()}</p>
                            </strong>
                            <hr />
                            <p>${user.vendedor_endereco}</p>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Usuário</td>
                        <td align="center" width="50%">${user.vendedor_usuario}</td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Descrição</td>
                        <td align="center" width="50%">${user.descricao}</td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Responsável</td>
                        <td align="center" width="50%">${user.responsavel}</td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Valor da transação</td>
                        <td align="center" width="50%">${self.formatCoin(user.valor)}</td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Tipo de lançamento</td>
                        <td align="center" width="50%">${user.tipo == 1 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">Horário da operação</td>
                        <td align="center" width="50%">${self.formatDate(user.horario)}</td>
                    </tr>
                </tbody>
            </table>`;

            if (!html) {
                Swal.fire(
                    'Falha',
                    'Não foi possível imprimir esse lançamento!',
                    'error'
                );
            } else {
                self.printIframeContentWindow(html);
            }
        },
        printIframeContentWindow(content) {

            let print_iframe = document.createElement('iframe');

            print_iframe.width = '0px';
            print_iframe.height = '0px';
            print_iframe.src = 'about:blank';
            print_iframe.style.visibility = 'hidden';

            print_iframe.onload = function() {
                let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                    print_script_tag.type = "text/javascript";
    
                let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                    print_script_tag.appendChild(print_script);

                print_iframe.contentWindow.document.body.innerHTML = `<html>
                    <head>
                        <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table{margin-bottom:10px}.table td {padding:10px}</style>
                        </head>
                    <body class="content">
                        ${content}
                    </body>
                </html>`;
                print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                print_iframe.contentWindow.Print();
            };
                
            document.body.appendChild(print_iframe);
            print_iframe.remove();
        },
        searchUser(event) {
            this.search = event.target.value;
        },
        salesmanOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.vendedor_usuario === o.vendedor_usuario)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.vendedor.localeCompare(b.vendedor)
            });
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    computed: {
        filteredListUsers() {
            return this.users.filter((item) => {
                return item.vendedor.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.vendedor_usuario.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>